import React from 'react'
import classNames from 'classnames'

interface Props {
    id: string
    label: string
}

const NavigationItem = (props: Props) => {
    const [websiteHash, setWebsiteHash] = React.useState(window.location.hash)

    React.useEffect(() => {
        const handleHashChange = () => {
            setWebsiteHash(window.location.hash)
        }

        window.addEventListener('hashchange', handleHashChange)

        return () => {
            window.removeEventListener('hashchange', handleHashChange)
        }
    }, [])

    return (
        <li key={props.id}>
            <a
                href={'#' + props.id}
                className="group inline-flex items-center gap-4 py-4 outline-none"
            >
                <span
                    className={classNames(
                        'h-px w-8 bg-slate-600 transition-all group-hover:w-14 group-hover:bg-slate-200',
                        {
                            '!w-14 !bg-slate-200':
                                websiteHash === '#' + props.id,
                        }
                    )}
                ></span>
                <span
                    className={classNames(
                        'text-xs font-bold uppercase tracking-widest transition-all group-hover:text-slate-200',
                        {
                            'text-slate-200': websiteHash === '#' + props.id,
                        }
                    )}
                >
                    {props.label}
                </span>
            </a>
        </li>
    )
}

export default NavigationItem
