import Experience from '@/components/Experience'
import { IconArrowUpRight } from '@tabler/icons-react'
import React from 'react'

const ExperiencePage = () => {
    return (
        <section id="experience" className="mb-20 space-y-16">
            <div className="mb-10 lg:hidden">
                <p className="text-sm font-bold uppercase tracking-widest text-slate-200">
                    experience
                </p>
            </div>
            <Experience
                from="2023"
                to="now"
                title="FullStack Developer · J.D. Geck GmbH"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                skills={[
                    'TypeScript',
                    'Git',
                    'JavaScript',
                    'Node.js',
                    'React.js',
                    'CSS',
                    'Docker',
                    'Rasa',
                    'GraphQL',
                    'Tailwind CSS',
                ]}
            />

            <Experience
                from="2023"
                to="oct 2023"
                title="Junior FullStack Developer · J.D. Geck GmbH"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                skills={['TypeScript', 'JavaScript', 'GraphQL', 'React.js']}
            />

            <Experience
                from="2022"
                to="feb 2023"
                title="Student assistant · Fachhochschule Südwestfalen"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                skills={[
                    'Git',
                    'Python',
                    'GitHub',
                    'JavaScript',
                    'Node.js',
                    'React.js',
                    'CSS',
                    'Docker',
                    'Express.js',
                    'JWT',
                ]}
            />

            <div className="w-fit">
                <a
                    href="#"
                    className="group relative font-bold text-slate-200 transition-all hover:text-teal-300"
                >
                    <span>View Full Résumé</span>
                    <span className="absolute -right-6 top-2 transition-all group-hover:-right-7 group-hover:top-1">
                        <IconArrowUpRight size={16} />
                    </span>
                </a>
            </div>
        </section>
    )
}

export default ExperiencePage
