import React from 'react'
import Landing from './pages/Landing/Landing'

function App() {
    return (
        <div className="box-border h-full px-[3rem] py-20 text-slate-400 max-sm:px-[1rem] max-sm:py-4 md:py-12 lg:px-[2.5rem] xl:px-[6rem]">
            <Landing />
        </div>
    )
}

export default App
