import { IconArrowUpRight } from '@tabler/icons-react'
import React from 'react'

interface Props {
    title: string
    description: string
    href: string
    imageSrc: string
}

const Project = (props: Props) => {
    const redirect = () => {
        window.open(props.href, '_blank')
    }

    return (
        <div
            className="group flex w-full cursor-pointer flex-col-reverse gap-4 rounded-sm py-4 transition-all hover:bg-teal-200/5 hover:shadow-inner hover:shadow-white/10 lg:flex-row lg:px-6"
            onClick={redirect}
        >
            <div>
                <img
                    src={props.imageSrc}
                    alt="project"
                    className="aspect-video rounded-sm outline outline-1 outline-slate-500/20"
                    height={200}
                    width={600}
                />
            </div>
            <div>
                <p className="relative inline-block font-bold text-slate-200 transition-all group-hover:text-teal-300">
                    <span>{props.title}</span>
                    <span className="absolute -right-6 top-2 transition-all group-hover:-right-7 group-hover:top-1">
                        <IconArrowUpRight size={16} />
                    </span>
                </p>
                <p className="mt-2">{props.description}</p>
            </div>
        </div>
    )
}

export default Project
