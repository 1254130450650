import React from 'react'

interface Props {
    from: string
    to: string
    title: string
    description: string
    skills: string[]
}

const Experience = (props: Props) => {
    return (
        <div className="flex flex-row gap-8 max-sm:flex-col max-sm:gap-2">
            <div className="mt-1">
                <p className="flex items-center gap-2 text-xs font-bold uppercase text-slate-500">
                    <span>{props.from}</span>
                    <span className="h-px w-3 bg-slate-500"></span>{' '}
                    <span>{props.to}</span>
                </p>
            </div>
            <div>
                <p className="mb-2 font-bold tracking-wide text-slate-200">
                    {props.title}
                </p>
                <p className="mb-6">{props.description}</p>
                <div>
                    <ul className="flex flex-wrap gap-2 text-sm">
                        {props.skills.map((skill: string) => {
                            return (
                                <li
                                    key={skill}
                                    className="rounded-full bg-teal-300/20 px-3 py-1 text-teal-300"
                                >
                                    {skill}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Experience
