import Project from '@/components/Project'
import { IconArrowUpRight } from '@tabler/icons-react'
import React from 'react'

const Projects = () => {
    return (
        <section id="projects" className="mb-20">
            <div className="mb-10 lg:hidden">
                <p className="text-sm font-bold uppercase tracking-widest text-slate-200">
                    projects
                </p>
            </div>
            <Project
                title="Project 1"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                href="#"
                imageSrc="https://via.placeholder.com/1920x1080"
            />

            <Project
                title="Project 2"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                href="#"
                imageSrc="https://via.placeholder.com/1920x1080"
            />

            <Project
                title="Project 3"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                href="#"
                imageSrc="https://via.placeholder.com/1920x1080"
            />

            <Project
                title="Project 4"
                description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti porro consequatur sequi laboriosam explicabo tempore eligendi omnis perspiciatis, nihil mollitia provident atque dignissimos eaque quibusdam, magnam illo tempora maiores quae."
                href="#"
                imageSrc="https://via.placeholder.com/1920x1080"
            />

            <div className="mt-8 w-fit">
                <a
                    href="#"
                    className="group relative font-bold text-slate-200 transition-all hover:text-teal-300"
                >
                    <span>View Full Project Archive</span>
                    <span className="absolute -right-6 top-2 transition-all group-hover:-right-7 group-hover:top-1">
                        <IconArrowUpRight size={16} />
                    </span>
                </a>
            </div>
        </section>
    )
}

export default Projects
