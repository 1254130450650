import React from 'react'

interface Props {
    href: string
    icon: React.ReactNode
}

const LinkImageButton = (props: Props) => {
    const redirect = () => {
        window.open(props.href, '_blank')
    }

    return (
        <button
            className="text-slate-400 hover:text-slate-200"
            onClick={redirect}
        >
            {props.icon}
        </button>
    )
}

export default LinkImageButton
