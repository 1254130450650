import { IconBrandInstagram, IconBrandLinkedin } from '@tabler/icons-react'
import React from 'react'
import classNames from 'classnames'
import Experience from '@/components/Experience'
import NavigationItem from '@/components/NavigationItem'
import About from '../About/About'
import ExperiencePage from '../Experience/ExperiencePage'
import LinkImageButton from '@/components/LinkImageButton'
import Projects from '../Projects/Projects'

const navigationItems = [
    {
        id: 'about',
        label: 'About',
    },
    {
        id: 'experience',
        label: 'Experience',
    },
    {
        id: 'projects',
        label: 'Projects',
    },
]

const Landing = () => {
    const [currentSection, setCurrentSection] = React.useState('about')

    React.useEffect(() => {
        function handleScroll() {
            const sections = document.querySelectorAll('section')
            sections.forEach((section) => {
                const sectionTop = section.offsetTop - 100
                const sectionHeight = section.clientHeight
                const scrollPosition = window.scrollY

                if (
                    scrollPosition >= sectionTop &&
                    scrollPosition < sectionTop + sectionHeight
                ) {
                    setCurrentSection(section.id)
                }
            })
        }

        window.addEventListener('scroll', handleScroll)

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    React.useEffect(() => {
        window.location.hash = currentSection
    }, [currentSection])

    return (
        <div className="mx-auto box-border flex min-h-full max-w-screen-xl flex-col px-6 py-12 font-sans md:px-12 md:py-20 lg:flex lg:flex-row lg:justify-between lg:gap-4 lg:px-24 lg:py-0">
            <div className="relative w-full lg:w-1/2">
                <div className="lg:sticky lg:top-20">
                    <h1 className="text-4xl font-bold tracking-tight text-slate-200">
                        Zoe Günther
                    </h1>
                    <h2 className="mt-2 text-lg font-medium tracking-tight text-slate-200">
                        FullStack Developer at J.D. Geck GmbH
                    </h2>
                    <p className="mt-4 max-w-xs leading-normal">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Atque minus, quae vel.
                    </p>
                    <nav className="mt-16 hidden lg:block">
                        <ul>
                            {navigationItems.map((navigationItem) => {
                                return (
                                    <NavigationItem
                                        key={navigationItem.id}
                                        id={navigationItem.id}
                                        label={navigationItem.label}
                                    />
                                )
                            })}
                        </ul>
                    </nav>
                    <footer className="mb-24 mt-10 flex items-center gap-4 lg:mb-0 lg:mt-20">
                        <LinkImageButton
                            href="https://www.linkedin.com/in/zoe-guenther/"
                            icon={<IconBrandLinkedin size={30} />}
                        />
                        <LinkImageButton
                            href="https://www.instagram.com/zography.g/"
                            icon={<IconBrandInstagram size={30} />}
                        />
                    </footer>
                </div>
            </div>
            <div className="w-full lg:w-1/2">
                <About />
                <ExperiencePage />
                <Projects />
            </div>
        </div>
    )
}

export default Landing
